#backToTop__button {
  @apply fixed rounded-full bg-primary-900 h-16 w-16 text-white flex justify-center items-center z-50 text-3xl;
  right: 5vw;
  bottom: 5vh;
  opacity: 0.8;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

#backToTop__button.hidden {
  opacity: 0;
  pointer-events: none;
}

#backToTop__button:hover {
  opacity: 1;
  transform: scale(1.05);
}