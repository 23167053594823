@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import "./footer.css";
@import "./header.css";
@import "./backToTop.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
  @apply bg-gray-300;
  font-size: calc(0.75em + 0.5vmin);
  max-width: 100vw;
  overflow-x: hidden;
}

body, html, * {
  box-sizing: border-box;
}

.container {
  width: 90%;
  max-width: 1250px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.breadcrumb_trail {
  @apply my-10 text-primary-600 text-lg;
}

.breadcrumb_trail > a:last-child {
  @apply pointer-events-none text-primary-1000;
}

.breadcrumb_trail > i {
  @apply mx-2 align-baseline;
}

.full_square__panel {
  @apply relative overflow-hidden rounded-xl text-white text-2xl flex flex-col justify-end items-baseline p-4 shrink-0 cursor-pointer;
  @apply hover:border-primary-500 hover:scale-105;
  transition: all 0.2s ease-in-out;
  border: 2px solid transparent;
  width: 20rem;
  height: 10rem;
}

.full_square__panel.current {
  @apply border-primary-400 after:opacity-50;
}

.full_square__panel:hover::after {
  @apply opacity-20;
}

.full_square__panel::after {
  content: '';
  transition: all 0.2s ease-in-out;
  background: linear-gradient(to bottom, rgb(152, 208, 53), black);
  @apply absolute top-0 left-0 right-0 bottom-0 opacity-0;
}

.full_square__panel:hover {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
}

.full_square__panel > p {
  @apply font-semibold;
}

.full_square__panel > span {
  @apply bg-white rounded-xl text-gray-1200 font-black lowercase px-2 py-0 mb-3 opacity-80 text-base;
}

.full_square__panel > img {
  @apply absolute top-0 left-0 right-0 bottom-0 h-full w-full;
  object-fit: cover;
}

.full_square__panel > *:not(img) {
  @apply relative z-10;
}


.saiba_mais {
  @apply text-white bg-primary-500 py-3 px-8 rounded-3xl;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  cursor: pointer;
}

.saiba_mais_secondary {
  @apply text-white bg-primary-1000 py-3 px-8 rounded-3xl;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.saiba_mais_secondary > i {
  @apply text-primary-300 opacity-100;
}

.saiba_mais > i, .saiba_mais_secondary > i {
  @apply ml-4 text-xl align-middle;
  opacity: 0.7;
  transition: transform 0.2s ease-in-out;
}

.saiba_mais:hover, .saiba_mais_secondary:hover {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4), 2px 2px 5px rgba(0, 0, 0, 0.2) inset;
}

.saiba_mais:hover > i, .saiba_mais_secondary:hover > i {
  transform: translateX(7px);
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

*::-webkit-scrollbar-thumb {
  background: #77a630;
  border: 0px none #ffffff;
  border-radius: 50px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #4f6d22;
}

*::-webkit-scrollbar-thumb:active {
  background: #4f6d22;
}

*::-webkit-scrollbar-track {
  background: #d1d1d1;
  border: 0px none #ffffff;
  border-radius: 100px;
}

*::-webkit-scrollbar-track:hover {
  background: #d1d1d1;
}

*::-webkit-scrollbar-track:active {
  background: #d1d1d1;
}

*::-webkit-scrollbar-corner {
  background: transparent;
}

input:disabled, button:disabled {
  background-color: lightgrey;
  opacity: 0.6;
  pointer-events: none;
}

.separator {
  margin-top: 25px;
}