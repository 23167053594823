.shadow-footer {
  box-shadow: 0px -2px 8px #0000000A;
}

.footer-link {
  @apply bg-primary-200 w-8 h-8 p-2 flex justify-center rounded-full ease-in-out duration-200 cursor-pointer;
  @apply hover:scale-110 hover:bg-primary-300
}

.footer-link:hover {
  box-shadow: 0px 0px 18px #9ED54A;
}