.shadow-header {
  box-shadow: 0px 3px 6px #0000001A;
}

.nav-link {
  @apply float-left text-lg h-6 mr-8 text-gray-1200 ease-in-out duration-200 hover:text-primary-500;
}

.nav-link__current {
  @apply text-primary-500 underline;
}

.language-link {
  @apply float-left px-3 h-8 w-12 text-gray-1200 ease-in-out duration-200 hover:text-primary-500 cursor-pointer text-center;
}

.language-link > button > img {
  object-fit: contain;
}

.language-link.current {
  background-color: #acff30;
  box-shadow: 2px 2px 3px #00000017;
  border-radius: 5px;
}

.language-link:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.dropdown {
  @apply overflow-hidden h-full relative hover:overflow-visible z-50;
}

.dropdown > div {
  @apply absolute top-full min-w-full text-left bg-white flex pt-5;
  box-shadow: 0px 3px 6px #0000001A;
}

.dropdown__option {
  @apply w-full box-content text-gray-1200 ease-in-out duration-200 hover:text-primary-500;
}

.dropdown__option > a {
  @apply whitespace-nowrap px-4 box-content;
  line-height: 2.3rem;
}

.dropdown__option:hover {
  box-shadow: 0px 2px 10px #0000001A;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;
}

.nav-container {
  display: block;
  position: relative;
  height: 60px;
  width: 60px
}

.nav-container .checkbox {
  position: absolute;
  display: block;
  height: 32px;
  width: 32px;
  top: 20px;
  left: 20px;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}

.nav-container .hamburger-lines {
  display: block;
  height: 26px;
  width: 32px;
  position: absolute;
  top: 17px;
  left: 20px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nav-container .hamburger-lines .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: #646464;
}

.nav-container .hamburger-lines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.nav-container .hamburger-lines .line2 {
  transition: transform 0.2s ease-in-out;
}

.nav-container .hamburger-lines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
  transform: rotate(45deg);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
  transform: scaleY(0);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
  transform: rotate(-45deg);
}

.nav-container .content {
  @apply bg-white text-xl z-30;
  width: 100vw;
  position: absolute;
  left: calc(100% + 1.5rem);
  top: 5rem;
  transform: translateX(-100%);
  max-height: 0px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}

.nav-container input[type="checkbox"]:checked ~ .content {
  max-height: 650px;
  box-shadow: 0px 10px 6px rgba(0, 0, 0, 0.2);
}

.secondary_dropdown > ul {
  max-height: 0px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}

.secondary_dropdown:hover > ul {
  max-height: 300px;
}