@import "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap";
.shadow-footer {
  box-shadow: 0 -2px 8px #0000000a;
}

.footer-link {
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(199 233 146 / var(--tw-bg-opacity) );
  border-radius: 9999px;
  justify-content: center;
  padding: .5rem;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.footer-link:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  --tw-bg-opacity: 1;
  background-color: rgb(158 213 74 / var(--tw-bg-opacity) );
  box-shadow: 0 0 18px #9ed54a;
}

.shadow-header {
  box-shadow: 0 3px 6px #0000001a;
}

.nav-link {
  float: left;
  height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(100 100 100 / var(--tw-text-opacity) );
  margin-right: 2rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.nav-link:hover {
  --tw-text-opacity: 1;
  color: rgb(113 159 42 / var(--tw-text-opacity) );
}

.nav-link__current {
  --tw-text-opacity: 1;
  color: rgb(113 159 42 / var(--tw-text-opacity) );
  text-decoration-line: underline;
}

.language-link {
  float: left;
  height: 2rem;
  width: 3rem;
  cursor: pointer;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(100 100 100 / var(--tw-text-opacity) );
  padding-left: .75rem;
  padding-right: .75rem;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.language-link:hover {
  --tw-text-opacity: 1;
  color: rgb(113 159 42 / var(--tw-text-opacity) );
}

.language-link > button > img {
  object-fit: contain;
}

.language-link.current {
  background-color: #acff30;
  border-radius: 5px;
  box-shadow: 2px 2px 3px #00000017;
}

.language-link:not(:last-child) {
  border-right: 1px solid #0003;
}

.dropdown {
  z-index: 50;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.dropdown:hover {
  overflow: visible;
}

.dropdown > div {
  min-width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
  text-align: left;
  padding-top: 1.25rem;
  display: flex;
  position: absolute;
  top: 100%;
  box-shadow: 0 3px 6px #0000001a;
}

.dropdown__option {
  box-sizing: content-box;
  width: 100%;
  --tw-text-opacity: 1;
  color: rgb(100 100 100 / var(--tw-text-opacity) );
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.dropdown__option:hover {
  --tw-text-opacity: 1;
  color: rgb(113 159 42 / var(--tw-text-opacity) );
}

.dropdown__option > a {
  box-sizing: content-box;
  white-space: nowrap;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 2.3rem;
}

.dropdown__option:hover {
  box-shadow: 0 2px 10px #0000001a;
}

.nav-container {
  height: 60px;
  width: 60px;
  justify-content: space-between;
  align-items: center;
  display: block;
  position: relative;
}

.nav-container .checkbox {
  height: 32px;
  width: 32px;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
  display: block;
  position: absolute;
  top: 20px;
  left: 20px;
}

.nav-container .hamburger-lines {
  height: 26px;
  width: 32px;
  z-index: 2;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: absolute;
  top: 17px;
  left: 20px;
}

.nav-container .hamburger-lines .line {
  height: 4px;
  width: 100%;
  background: #646464;
  border-radius: 10px;
  display: block;
}

.nav-container .hamburger-lines .line1 {
  transform-origin: 0 0;
  transition: transform .4s ease-in-out;
}

.nav-container .hamburger-lines .line2 {
  transition: transform .2s ease-in-out;
}

.nav-container .hamburger-lines .line3 {
  transform-origin: 0 100%;
  transition: transform .4s ease-in-out;
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
  transform: rotate(45deg);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
  transform: scaleY(0);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
  transform: rotate(-45deg);
}

.nav-container .content {
  z-index: 30;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
  width: 100vw;
  max-height: 0;
  font-size: 1.25rem;
  line-height: 1.75rem;
  transition: all .2s ease-in-out;
  position: absolute;
  top: 5rem;
  left: calc(100% + 1.5rem);
  overflow: hidden;
  transform: translateX(-100%);
}

.nav-container input[type="checkbox"]:checked ~ .content {
  max-height: 650px;
  box-shadow: 0 10px 6px #0003;
}

.secondary_dropdown > ul {
  max-height: 0;
  transition: all .2s ease-in-out;
  overflow: hidden;
}

.secondary_dropdown:hover > ul {
  max-height: 300px;
}

#backToTop__button {
  z-index: 50;
  height: 4rem;
  width: 4rem;
  --tw-bg-opacity: 1;
  background-color: rgb(67 97 20 / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
  opacity: .8;
  cursor: pointer;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  font-size: 1.875rem;
  line-height: 2.25rem;
  transition: all .2s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 5vh;
  right: 5vw;
}

#backToTop__button.hidden {
  opacity: 0;
  pointer-events: none;
}

#backToTop__button:hover {
  opacity: 1;
  transform: scale(1.05);
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: Roboto, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline:  auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-1\/2 {
  left: 50%;
}

.top-1\/2 {
  top: 50%;
}

.top-6 {
  top: 1.5rem;
}

.left-3 {
  left: .75rem;
}

.-top-6 {
  top: -1.5rem;
}

.left-full {
  left: 100%;
}

.z-50 {
  z-index: 50;
}

.z-10 {
  z-index: 10;
}

.m-0 {
  margin: 0;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.-my-1 {
  margin-top: -.25rem;
  margin-bottom: -.25rem;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-4 {
  margin-top: 1rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mt-28 {
  margin-top: 7rem;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-2 {
  margin-left: .5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-64 {
  height: 16rem;
}

.h-3\/6 {
  height: 50%;
}

.h-full {
  height: 100%;
}

.h-12 {
  height: 3rem;
}

.h-fit {
  height: fit-content;
}

.h-72 {
  height: 18rem;
}

.h-2 {
  height: .5rem;
}

.h-6 {
  height: 1.5rem;
}

.h-auto {
  height: auto;
}

.h-28 {
  height: 7rem;
}

.h-5\/6 {
  height: 83.3333%;
}

.max-h-28 {
  max-height: 7rem;
}

.max-h-0 {
  max-height: 0;
}

.w-64 {
  width: 16rem;
}

.w-full {
  width: 100%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-1\/2 {
  width: 50%;
}

.w-12 {
  width: 3rem;
}

.w-9\/12 {
  width: 75%;
}

.w-3\/12, .w-1\/4 {
  width: 25%;
}

.w-3\/4 {
  width: 75%;
}

.w-4 {
  width: 1rem;
}

.w-6 {
  width: 1.5rem;
}

.w-3 {
  width: .75rem;
}

.w-11\/12 {
  width: 91.6667%;
}

.max-w-full {
  max-width: 100%;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.-translate-y-full {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.-translate-x-3 {
  --tw-translate-x: -.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.content-start {
  align-content: flex-start;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-1 {
  gap: .25rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-24 {
  gap: 6rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-6 {
  gap: 1.5rem;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.break-all {
  word-break: break-all;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border {
  border-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-8 {
  border-bottom-width: 8px;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity) );
}

.border-primary-400 {
  --tw-border-opacity: 1;
  border-color: rgb(152 208 53 / var(--tw-border-opacity) );
}

.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgb(214 214 214 / var(--tw-border-opacity) );
}

.border-transparent {
  border-color: #0000;
}

.border-primary-500 {
  --tw-border-opacity: 1;
  border-color: rgb(113 159 42 / var(--tw-border-opacity) );
}

.border-gray-1300 {
  --tw-border-opacity: 1;
  border-color: rgb(73 73 73 / var(--tw-border-opacity) );
}

.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity) );
}

.border-l-primary-400 {
  --tw-border-opacity: 1;
  border-left-color: rgb(152 208 53 / var(--tw-border-opacity) );
}

.border-t-primary-400 {
  --tw-border-opacity: 1;
  border-top-color: rgb(152 208 53 / var(--tw-border-opacity) );
}

.border-b-primary-700 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(76 115 17 / var(--tw-border-opacity) );
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 239 239 / var(--tw-bg-opacity) );
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 239 239 / var(--tw-bg-opacity) );
}

.bg-black\/50 {
  background-color: #00000080;
}

.bg-primary-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(199 233 146 / var(--tw-bg-opacity) );
}

.bg-primary-1000 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 73 59 / var(--tw-bg-opacity) );
}

.bg-primary-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(152 208 53 / var(--tw-bg-opacity) );
}

.p-2 {
  padding: .5rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-12 {
  padding: 3rem;
}

.p-4 {
  padding: 1rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pr-64 {
  padding-right: 16rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-28 {
  padding-bottom: 7rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-thin {
  font-weight: 100;
}

.font-black {
  font-weight: 900;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.leading-tight {
  line-height: 1.25;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.text-gray-1300 {
  --tw-text-opacity: 1;
  color: rgb(73 73 73 / var(--tw-text-opacity) );
}

.text-primary-500 {
  --tw-text-opacity: 1;
  color: rgb(113 159 42 / var(--tw-text-opacity) );
}

.text-primary-1100 {
  --tw-text-opacity: 1;
  color: rgb(47 74 11 / var(--tw-text-opacity) );
}

.text-gray-1100 {
  --tw-text-opacity: 1;
  color: rgb(114 114 114 / var(--tw-text-opacity) );
}

.text-gray-1000 {
  --tw-text-opacity: 1;
  color: rgb(174 174 174 / var(--tw-text-opacity) );
}

.text-primary-600 {
  --tw-text-opacity: 1;
  color: rgb(83 130 0 / var(--tw-text-opacity) );
}

.text-primary-800 {
  --tw-text-opacity: 1;
  color: rgb(70 108 4 / var(--tw-text-opacity) );
}

.text-gray-1200 {
  --tw-text-opacity: 1;
  color: rgb(100 100 100 / var(--tw-text-opacity) );
}

.text-primary-1000 {
  --tw-text-opacity: 1;
  color: rgb(55 73 59 / var(--tw-text-opacity) );
}

.text-primary-900 {
  --tw-text-opacity: 1;
  color: rgb(67 97 20 / var(--tw-text-opacity) );
}

.underline {
  text-decoration-line: underline;
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

body, html {
  --tw-bg-opacity: 1;
  background-color: rgb(239 239 239 / var(--tw-bg-opacity) );
  max-width: 100vw;
  font-size: calc(.75em + .5vmin);
  overflow-x: hidden;
}

body, html, * {
  box-sizing: border-box;
}

.container {
  width: 90%;
  max-width: 1250px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.breadcrumb_trail {
  --tw-text-opacity: 1;
  color: rgb(83 130 0 / var(--tw-text-opacity) );
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.breadcrumb_trail > a:last-child {
  pointer-events: none;
  --tw-text-opacity: 1;
  color: rgb(55 73 59 / var(--tw-text-opacity) );
}

.breadcrumb_trail > i {
  vertical-align: baseline;
  margin-left: .5rem;
  margin-right: .5rem;
}

.full_square__panel {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
  border-radius: .75rem;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: baseline;
  padding: 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.full_square__panel:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  --tw-border-opacity: 1;
  border-color: rgb(113 159 42 / var(--tw-border-opacity) );
}

.full_square__panel {
  width: 20rem;
  height: 10rem;
  border: 2px solid #0000;
  transition: all .2s ease-in-out;
}

.full_square__panel.current {
  --tw-border-opacity: 1;
  border-color: rgb(152 208 53 / var(--tw-border-opacity) );
}

.full_square__panel.current:after {
  content: var(--tw-content);
  opacity: .5;
}

.full_square__panel:hover:after {
  opacity: .2;
}

.full_square__panel:after {
  content: "";
  opacity: 0;
  background: linear-gradient(#98d035, #000);
  transition: all .2s ease-in-out;
  position: absolute;
  inset: 0;
}

.full_square__panel:hover {
  box-shadow: 0 0 20px #0000004d;
}

.full_square__panel > p {
  font-weight: 600;
}

.full_square__panel > span {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
  text-transform: lowercase;
  --tw-text-opacity: 1;
  color: rgb(100 100 100 / var(--tw-text-opacity) );
  opacity: .8;
  border-radius: .75rem;
  margin-bottom: .75rem;
  padding: 0 .5rem;
  font-size: 1rem;
  font-weight: 900;
  line-height: 1.5rem;
}

.full_square__panel > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  inset: 0;
}

.full_square__panel > :not(img) {
  z-index: 10;
  position: relative;
}

.saiba_mais {
  --tw-bg-opacity: 1;
  background-color: rgb(113 159 42 / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
  white-space: nowrap;
  cursor: pointer;
  border-radius: 1.5rem;
  padding: .75rem 2rem;
  transition: all .2s ease-in-out;
  box-shadow: 0 3px 6px #0006;
}

.saiba_mais_secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(55 73 59 / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
  cursor: pointer;
  border-radius: 1.5rem;
  padding: .75rem 2rem;
  transition: all .2s ease-in-out;
  box-shadow: 0 3px 6px #0006;
}

.saiba_mais_secondary > i {
  --tw-text-opacity: 1;
  color: rgb(158 213 74 / var(--tw-text-opacity) );
  opacity: 1;
}

.saiba_mais > i, .saiba_mais_secondary > i {
  vertical-align: middle;
  opacity: .7;
  margin-left: 1rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  transition: transform .2s ease-in-out;
}

.saiba_mais:hover, .saiba_mais_secondary:hover {
  box-shadow: 0 3px 6px #0006, inset 2px 2px 5px #0003;
}

.saiba_mais:hover > i, .saiba_mais_secondary:hover > i {
  transform: translateX(7px);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: #77a630;
  border: none;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #4f6d22;
}

::-webkit-scrollbar-thumb:active {
  background: #4f6d22;
}

::-webkit-scrollbar-track {
  background: #d1d1d1;
  border: none;
  border-radius: 100px;
}

::-webkit-scrollbar-track:hover {
  background: #d1d1d1;
}

::-webkit-scrollbar-track:active {
  background: #d1d1d1;
}

::-webkit-scrollbar-corner {
  background: none;
}

input:disabled, button:disabled {
  opacity: .6;
  pointer-events: none;
  background-color: #d3d3d3;
}

.separator {
  margin-top: 25px;
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:left-1\/2:before {
  content: var(--tw-content);
  left: 50%;
}

.before\:top-1\/2:before {
  content: var(--tw-content);
  top: 50%;
}

.before\:-translate-x-1\/2:before {
  content: var(--tw-content);
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.before\:-translate-y-1\/2:before {
  content: var(--tw-content);
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:bg-primary-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(199 233 146 / var(--tw-bg-opacity) );
}

@media (min-width: 1024px) {
  .lg\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mt-4 {
    margin-top: 1rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-2\/5 {
    width: 40%;
  }

  .lg\:w-3\/5 {
    width: 60%;
  }

  .lg\:w-4\/6 {
    width: 66.6667%;
  }

  .lg\:w-3\/12 {
    width: 25%;
  }

  .lg\:w-9\/12 {
    width: 75%;
  }

  .lg\:w-11\/12 {
    width: 91.6667%;
  }

  .lg\:w-1\/3 {
    width: 33.3333%;
  }

  .lg\:flex-wrap {
    flex-wrap: wrap;
  }

  .lg\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:overflow-hidden {
    overflow: hidden;
  }

  .lg\:\!overflow-visible {
    overflow: visible !important;
  }

  .lg\:overflow-visible {
    overflow: visible;
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:flex {
    display: flex;
  }

  .\32 xl\:hidden {
    display: none;
  }
}

/*# sourceMappingURL=index.d4b26177.css.map */
